var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{attrs:{"query":_vm.indexersQuery,"variables":_vm.indexersQueryVariables},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
return [(!loading)?_c('v-data-table',{staticClass:"indexers-table",attrs:{"headers":_vm.headers,"items":data ? _vm.addVirtualFields(data.indexers) : [],"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"search":_vm.search,"custom-filter":_vm.looseSearch,"mobile-breakpoint":"0"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){return [_c('v-tooltip',{key:h.value,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(h.tooltip))])])]}}}),{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',{staticClass:"text-center"},[_vm._l((items),function(item){return _c('tr',{key:item.id,on:{"mouseover":function($event){_vm.hovered = item.id},"mouseleave":function($event){_vm.hovered = false}}},[_c('td',[_c('span',{staticClass:"rank"},[_vm._v(_vm._s(item.place))])]),_c('td',{staticClass:"indexer-name"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.id))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"clipboard",rawName:"v-clipboard",value:(item.id),expression:"item.id"},{name:"ripple",rawName:"v-ripple",value:({class: 'green--text'}),expression:"{class: 'green--text'}"}],staticClass:"ml-2 copy-button",style:({visibility: _vm.hovered === item.id ? 'visible' : 'hidden'}),attrs:{"icon":""},on:{"click":function($event){_vm.copied = true},"mouseleave":function($event){_vm.copied = false}}},on),[(_vm.copied)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle-outline")]):_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy address to clipboard")])])],1),_c('td',[_vm._v(" "+_vm._s(_vm.formatBigNumber(+item.rewardsEarned))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatBigNumber(+item.stakedTokens))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatBigNumber(+item.delegatedTokens))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.delegationRatio.toFixed(2))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.queryFeeCut / Math.pow( 10, 4 ))+"% ")]),_c('td',[_vm._v(" "+_vm._s(item.indexingRewardCut / Math.pow( 10, 4 ))+"% ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDecimals(item.queryFeeRebates))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDecimals(item.queryFeesCollected))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.rebateRatio)+" ")])])}),(error)?[_c('tr',[_c('td',{attrs:{"colspan":"11"}},[_c('h2',[_vm._v("Live data unavailable.")])])])]:_vm._e()],2)]}}],null,true)}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }