


























































































import Vue from 'vue';

import { subgraphsQuery, subgraphQueryVariables } from '../graphql/subgraphs';
import { formatBigNumber, createIpfsHash, formatDecimals } from '../utils/helpers';
import { SUBGRAPHS_TABLE_HEADERS } from '../utils/constants';

export default Vue.extend({
  name: 'Subgraphs',

  props: {
    search: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    subgraphsQuery,
    subgraphQueryVariables,
    headers: SUBGRAPHS_TABLE_HEADERS,
    sortDesc: true,
    sortBy: 'currentVersion.subgraphDeployment.queryFeesAmount',
  }),

  methods: {
    formatBigNumber,
    createIpfsHash,
    formatDecimals,
    addIpfsHashToTable(subgraphs: any): any {
      return subgraphs?.map((subgraph: any) => ({
        ...subgraph,
        ipfsHash: this.createIpfsHash(subgraph.currentVersion.subgraphDeployment.id),
      }));
    },
  },
});
