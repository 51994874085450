import numeral from 'numeral';
import { ethers } from 'ethers';

export const formatBigNumber = (bigNumber: number): string => numeral(bigNumber / 10 ** 18).format('0.0a');

export const formatNumber = (num: number): string => numeral(num).format('0.0a');

export const formatGRT = (grtNum: number): string => numeral(ethers.utils.formatUnits(grtNum)).format('0,0.00');

export const formatDate = (date: number): string => (new Date(date * 1000)).toLocaleString();

export const formatDecimals = (decimalNum: string): string => (
  +decimalNum / 10 ** 18 ? (+decimalNum / 10 ** 18).toFixed(4) : '0'
);

export const createIpfsHash = (hash: string): string => ethers.utils.base58.encode(
  [0x12, 0x20, ...ethers.utils.arrayify(hash)],
);

export const indexerNameFromAccount = (account: any): string => (
  account.defaultName ? account.defaultName.name : account.id
);

export const formatParticipantName = (indexer: any) => {
  const { account } = indexer;
  if (account?.defaultName) {
    return account?.defaultName?.name;
  }

  return `
    ${account?.id.substring(0, 6)}—${account?.id.substring(account?.id.length - 6, account?.id.length)}
  `;
};

export const looseSearch = (_: any, search: string, item: any) => {
  const lowerCaseSearch = search.toLowerCase();

  return Object.values(item).some((v: any) => v && v.toString().toLowerCase().includes(lowerCaseSearch));
};
