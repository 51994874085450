import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Dashboard from '@/views/Dashboard.vue';
import Subgraph from '@/components/Subgraph.vue';
import User from '@/components/User.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/subgraph/:subgraphId',
    name: 'Subgraph',
    component: Subgraph,
    props: true,
  },
  {
    path: '/user/:userId',
    name: 'User',
    component: User,
    props: true,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
