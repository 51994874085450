import gql from 'graphql-tag';

const user = gql`
 query account($id: ID!, $first: Int, $skip: Int!) {
  graphAccount(id: $id) {
    id
    createdAt
    developerCreatedAt
    defaultName {
      id
      name
    }
    balance
    description
    website
    image
    codeRepository
    subgraphQueryFees
    indexer {
      id
      createdAt
      stakedTokens
      allocatedTokens
      lockedTokens
      tokensLockedUntil
      annualizedReturn
      tokenCapacity
      indexingRewardCut
      queryFeeCut
      delegatorParameterCooldown
      delegatedTokens
      delegatorShares
      url
      allocations(orderBy: allocatedTokens, orderDirection: desc, first: 1000, where: {status: Active}) {
        id
        createdAt
        status
        allocatedTokens
        queryFeesCollected
        queryFeeRebates
        curatorRewards
        totalReturn
        subgraphDeployment {
          id
          originalName
        }
      }
      rewardsEarned
      queryFeesCollected
      totalReturn
    }
    # curator {
    #   id
    #   totalNameSignalledTokens
    #   totalNameUnsignalledTokens
    #   realizedRewards
    #   totalReturn
    #   nameSignals {
    #     id
    #     nameSignal
    #     subgraph {
    #       id
    #       displayName
    #       description
    #       image
    #       createdAt
    #       updatedAt
    #       signalledTokens
    #       unsignalledTokens
    #       nameSignalAmount
    #       owner {
    #         id
    #         defaultName {
    #           id
    #           name
    #         }
    #       }
    #       currentVersion {
    #         id
    #         subgraphDeployment {
    #           id
    #           stakedTokens
    #           queryFeesAmount
    #         }
    #       }
    #     }
    #   }
    # }
    subgraphs(where: {currentVersion_not: null, displayName_not: ""}) {
      id
      displayName
      description
      image
      createdAt
      signalledTokens
      owner {
        id
        defaultName {
          id
          name
        }
      }
      currentVersion {
        id
        subgraphDeployment {
          id
          stakedTokens
          queryFeesAmount
        }
      }
    }
  }
}
`;

export default user;
