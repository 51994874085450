


















































































import Vue from 'vue';

import { curatorsQuery, curatorsQueryVariables } from '../graphql/curators';
import {
  formatBigNumber, formatDate, formatParticipantName, looseSearch,
} from '../utils/helpers';
import { DELEGATORS_TABLE_HEADERS } from '../utils/constants';

export default Vue.extend({
  name: 'Curators',

  props: {
    search: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    curatorsQuery,
    curatorsQueryVariables,
    headers: DELEGATORS_TABLE_HEADERS,
    sortDesc: true,
    sortBy: 'totalNameSignalledTokens',
  }),

  methods: {
    formatBigNumber,
    formatDate,
    formatParticipantName,
    looseSearch,
    computeCurationValueFromNameSignalsList(nameSignals: any): any {
      return nameSignals.reduce((acc: number, cur: any) => acc + cur.nameSignal / 10 ** 18, 0);
    },
    calculateCurationValue(curators: any): any {
      return curators?.map((curator: any) => ({
        ...curator,
        name: this.formatParticipantName(curator),
        curationValue: +(this.computeCurationValueFromNameSignalsList(curator.nameSignals)).toFixed(4),
        subgraphsCurrentlyCurating: curator.nameSignals.reduce(
          (acc: any, cur: any) => acc + (+cur.nameSignal === 0 ? 0 : 1), 0,
        ),
      }));
    },
  },
});
