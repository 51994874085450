








































































































import Vue from 'vue';

import { uniqBy, reverse } from 'lodash-es';

import Network from '@/components/Network.vue';
import CompareReturns from '@/components/CompareReturns.vue';
import Indexers from '@/components/Indexers.vue';
import Curators from '@/components/Curators.vue';
import Subgraphs from '@/components/Subgraphs.vue';

import { graphAccountNamesQuery, graphAccountNamesQueryVariables } from '../graphql/graphAccountNames';

import { GraphAccountNames, TokenLockWallet, IndexerName } from '../types';

import tokenLockWallets from '../assets/tokenLockWallets.json';

export default Vue.extend({
  name: 'Dashboard',

  components: {
    Network,
    CompareReturns,
    Indexers,
    Curators,
    Subgraphs,
  },

  apollo: {
    graphAccountNames: {
      query: graphAccountNamesQuery,
      variables: graphAccountNamesQueryVariables,
    },
  },

  data: () => ({
    tab: null,
    search: '',
    graphAccountNames: [],
  }),

  computed: {
    indexerNames(): IndexerName[] {
      return uniqBy(reverse(this.graphAccountNames), ({ graphAccount }: GraphAccountNames) => graphAccount.id)
        .filter((v, i, a) => a.indexOf(v) === i)
        .map(({ name, graphAccount }: GraphAccountNames) => {
          const matchedAccount = tokenLockWallets.find(
            ({ beneficiary }: TokenLockWallet) => beneficiary === graphAccount.id,
          ) as TokenLockWallet | undefined;

          return { id: matchedAccount?.tokenLockWallet, name: `${name}.eth` };
        });
    },
  },
});
