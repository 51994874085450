export const DELEGATION_TABLE_HEADERS = [
  {
    text: 'Indexer',
    value: 'name',
    align: 'center',
    tooltip: 'The Indexers\' public address',
  },
  {
    text: 'Historic ROI',
    value: 'historicROI',
    align: 'center',
    tooltip: 'The return on investment based on delegated funds since running the indexer within Mainnet. (in %)',
  },
  {
    text: 'Delegation Capacity',
    value: 'delegationCapacity',
    align: 'center',
    tooltip: 'The maximum amount of GRT the indexer can receive from delegators.',
  },
  {
    text: 'Allocated',
    value: 'allocated',
    align: 'center',
    tooltip: `The ratio of allocated GRT compared to total available funds (stake owned + delegated) within an indexer. 
        (< 100%: underallocated, > 100%: overallocated)`,
  },
  {
    text: 'Fee cut',
    value: 'queryFeeCut',
    align: 'center',
    tooltip: 'The share of query fee rewards that the indexer keeps for him/herself. The rest is passed on to the indexers\' delegators.',
  },
  {
    text: 'Rewards cut',
    value: 'indexingRewardCut',
    align: 'center',
    tooltip: 'The share of total indexer rewards that the indexer keeps him/herself. The rest is passed on to the indexers\' delegators.',
  },
  {
    text: 'Running since',
    value: 'createdAt',
    align: 'center',
    tooltip: 'Days since the Indexer joined The Graph Mainnet.',
  },
];

export const INDEXERS_TABLE_HEADERS = [
  {
    text: 'Reward Rank',
    value: '',
    align: 'center',
    tooltip: 'The Indexers\' rank according to its\' cumulative indexing rewards',
  },
  {
    text: 'Indexer Name',
    value: 'name',
    align: 'center',
    tooltip: 'The Indexers\' public address',
  },
  {
    text: 'Indexing Rewards',
    value: 'rewardsEarned',
    align: 'center',
    tooltip: `The total amount of Indexer Rewards earned by the indexer.
      Indexer rewards are newly issued tokens that enter the supply with each epoch. (Initial issuance rate: 3% annually)`,
  },
  {
    text: 'Stake Owned',
    value: 'stakedTokens',
    align: 'center',
    tooltip: 'The Indexers\' own deposited stake in GRT, which might be subject to slashing in case of malicious behavior by the indexer.',
  },
  {
    text: 'Delegated',
    value: 'delegatedTokens',
    align: 'center',
    tooltip: 'Amount of GRT the indexer manages for his/her delegators. This cannot be subject to slashing.',
  },
  {
    text: 'Delegation Ratio',
    value: 'delegationRatio',
    align: 'center',
    tooltip: `The ratio between Delegated and Stake Owned. Value can range from 0 to 16.
      A value of 16 means the Indexer has no more delegation capacity.`,
  },
  {
    text: 'Fee cut',
    value: 'queryFeeCut',
    align: 'center',
    tooltip: 'The share of query fee rewards that the indexer keeps for him/herself. The rest is passed on to the indexers\' delegators.',
  },
  {
    text: 'Rewards cut',
    value: 'indexingRewardCut',
    align: 'center',
    tooltip: 'The share of total indexer rewards that the indexer keeps him/herself. The rest is passed on to the indexers\' delegators.',
  },
  {
    text: 'Fee Rebates',
    value: 'queryFeeRebates',
    align: 'center',
    tooltip: 'The amount of query fees the indexer has received from the rebate pool.',
  },
  {
    text: 'Query Fees',
    value: 'queryFeesCollected',
    align: 'center',
    tooltip: 'The total amount of fees collected for processing indexing queries.',
  },
  {
    text: 'Rebate Ratio',
    value: 'rebateRatio',
    align: 'center',
    tooltip: `The rebate ratio indicates the balance between queries served and rebate fees collected by the indexer.
      This indicates indexing efficiency.`,
  },
];

export const DELEGATORS_TABLE_HEADERS = [
  {
    text: 'Signal Rank',
    value: '',
    align: 'center',
    tooltip: 'The curators\' rank according to the total signal',
  },
  {
    text: 'Name',
    value: 'account.defaultName.name',
    align: 'center',
    tooltip: 'The curators\' public address',
  },
  {
    text: 'Signaled',
    value: 'totalNameSignalledTokens',
    align: 'center',
    tooltip: 'The amount of tokens deposited into the subgraphs\' bonding curve.',
  },
  {
    text: 'Unsignalled',
    value: 'totalNameUnsignalledTokens',
    align: 'center',
    tooltip: '',
  },
  {
    text: 'Curation value',
    value: 'curationValue',
    align: 'center',
    tooltip: `The value of the total amount of curation shares,
      which entitle curators to a portion of future query fees collected on that subgraph.`,
  },
  {
    text: 'Subgraphs',
    value: 'subgraphsCurrentlyCurating',
    align: 'center',
    tooltip: 'The amount of subgraphs the curator is signaling on.',
  },
  {
    text: 'Curating since',
    value: 'createdAt',
    align: 'center',
    tooltip: 'The date that the curator started signaling.',
  },
];

export const SUBGRAPHS_TABLE_HEADERS = [
  {
    text: '',
    value: 'image',
    tooltip: '',
  },
  {
    text: 'Subgraph',
    value: 'displayName',
    align: 'center',
    tooltip: 'The name of the subgraph.',
  },
  {
    text: 'Subgraph address and ipfs hash',
    value: 'ipfsHash',
    align: 'center',
    tooltip: 'The subgraphs\' public address and its corresponding IPFS hash.',
  },
  {
    text: 'Query fees amount',
    value: 'currentVersion.subgraphDeployment.queryFeesAmount',
    align: 'center',
    tooltip: 'The total amount of query fees earned by indexers for servicing indexing queries for the respective subgraph.',
  },
  {
    text: 'Query fee rebates',
    value: 'currentVersion.subgraphDeployment.queryFeeRebates',
    align: 'center',
    tooltip: 'The amount of query fees the subgraph has contributed from the rebate pool.',
  },
  {
    text: 'Staked tokens',
    value: 'currentVersion.subgraphDeployment.stakedTokens',
    align: 'center',
    tooltip: 'The amount of GRT that indexers have allocated to the respective subgraph.',
  },
  {
    text: 'Signaled tokens',
    value: 'currentVersion.subgraphDeployment.signalledTokens',
    align: 'center',
    tooltip: 'The amount of GRT that curators have signaled on the respective subgraph.',
  },
];
