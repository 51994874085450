






















































































































import Vue from 'vue';
import { orderBy } from 'lodash-es';
import { utils } from 'ethers';

import { indexersQuery, indexersQueryVariables } from '../graphql/indexers';
import {
  formatBigNumber, formatGRT, formatDate, formatDecimals, formatParticipantName, looseSearch,
} from '../utils/helpers';
import { INDEXERS_TABLE_HEADERS } from '../utils/constants';

import { IndexerName } from '../types';

export default Vue.extend({
  name: 'Indexers',

  props: {
    search: {
      type: String,
      default: '',
    },
    indexerNames: {
      type: Array as () => IndexerName[],
      default: () => [],
    },
  },

  data: () => ({
    indexersQuery,
    indexersQueryVariables,
    headers: INDEXERS_TABLE_HEADERS,
    sortDesc: true,
    sortBy: 'rewardsEarned',
    hovered: false,
    copied: false,
  }),

  methods: {
    formatBigNumber,
    formatGRT,
    formatDate,
    formatDecimals,
    formatParticipantName,
    looseSearch,
    addVirtualFields(indexers: any): any {
      const indexersSortedByRewards = orderBy(indexers, (indexer: any) => +indexer.rewardsEarned, ['desc']);

      return indexersSortedByRewards
        .map((indexer: any, idx: number) => {
          const indexerName = this.indexerNames.find(({ id }) => id === indexer.id) as IndexerName;

          return {
            ...indexer,
            place: idx + 1,
            name: indexerName ? indexerName.name : this.formatParticipantName(indexer),
            rebateRatio: Number.isNaN(indexer.queryFeeRebates / indexer.queryFeesCollected)
              ? 0
              : (indexer.queryFeeRebates / indexer.queryFeesCollected).toFixed(4),
            delegationRatio: +utils.formatUnits(indexer.delegatedTokens) / +utils.formatUnits(indexer.stakedTokens),
          };
        });
    },
  },
});
