import Vue from 'vue';
import VueMeta from 'vue-meta';
import Clipboard from 'v-clipboard';

import App from './App.vue';

import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import { createProvider } from './vue-apollo';

Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(Clipboard);

new Vue({
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app');
