import gql from 'graphql-tag';

export const indexersQuery = gql`
query indexers($orderBy: Indexer_orderBy, $orderDirection: OrderDirection, $first: Int, $skip: Int) {
  indexers(orderBy: $orderBy, orderDirection: $orderDirection, first: $first, skip: $skip) {
    id
    createdAt
    account {
      id
      defaultName {
        id
        name
      }
      image
    }
    stakedTokens
    queryFeeCut
    indexingRewardCut
    url
    rewardsEarned
    queryFeesCollected
    queryFeeRebates
    tokenCapacity
    allocatedTokens
    availableStake
    delegatedTokens
  }
}
`;

export const indexersQueryVariables = {
  orderBy: 'stakedTokens',
  orderDirection: 'desc',
  first: 1000,
  skip: 0,
};
