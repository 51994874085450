





















































































import Vue from 'vue';
import { uniqBy } from 'lodash-es';

import allocationsQuery from '../graphql/allocations';
import { formatBigNumber, formatDate, indexerNameFromAccount } from '../utils/helpers';

export default Vue.extend({
  name: 'Curators',

  props: {
    subgraphId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      allocationsQuery,
      headers: [
        { text: 'Indexer', value: 'indexer.account.defaultName.name', align: 'center' },
        { text: 'Rewards Earned', value: 'indexer.rewardsEarned', align: 'center' },
        { text: 'Stake Allocated', value: 'indexer.stakedTokens', align: 'center' },
        { text: 'Query Fees Collected', value: 'indexer.queryFeesCollected', align: 'center' },
        { text: 'Query Fee Rebates', value: 'indexer.queryFeeRebates', align: 'center' },
        { text: 'Fee cut', value: 'indexer.queryFeeCut', align: 'center' },
        { text: 'Rewards cut', value: 'indexer.indexingRewardCut', align: 'center' },
      ],
      search: '',
      sortBy: 'indexer.rewardsEarned',
      sortDesc: true,
    };
  },

  methods: {
    formatBigNumber,
    formatDate,
    indexerNameFromAccount,
    removeDuplicateIndexers(allocations: any): any {
      return uniqBy(allocations, (allocation: any) => allocation.indexer.account.id);
    },
  },
});
