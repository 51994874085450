
























































































import Vue from 'vue';

import { networkQuery, networkQueryVariables } from '../graphql/network';
import { formatBigNumber } from '../utils/helpers';

export default Vue.extend({
  name: 'Network',

  data: () => ({
    networkQuery,
    networkQueryVariables,
    headers: [
      { text: 'Subgraph count', value: 'subgraphCount', align: 'center' },
      { text: 'Indexing', value: 'totalTokensStaked', align: 'center' },
      { text: 'Curating', value: 'totalTokensSignalled', align: 'center' },
      { text: 'Total query fees', value: 'totalQueryFees', align: 'center' },
      { text: 'Total delegated tokens', value: 'totalDelegatedTokens', align: 'center' },
      { text: 'GRTinETH', value: 'GRTinETH', align: 'center' },
      { text: 'GRTinUSD', value: 'GRTinUSD', align: 'center' },
    ],
  }),

  methods: {
    formatBigNumber,
    addColorToFormattedNumber(value: number): string {
      const formattedValue = this.formatBigNumber(value);
      const numbers = formattedValue.substring(0, formattedValue.length - 1);
      const multiplier = formattedValue.substring(formattedValue.length - 1);

      return `${numbers}<span class="multiplier-color">${multiplier}</span>`;
    },
  },
});
