import gql from 'graphql-tag';

const allocationsQuery = gql`
   query allocations($deploymentID: ID!, $first: Int, $skip: Int) {
  allocations(first: $first, skip: $skip, where: {subgraphDeployment: $deploymentID}) {
    id
    price
    allocatedTokens
    subgraphDeployment {
      originalName
    }
    indexer {
      id
      createdAt
      account {
        id
        defaultName {
          id
          name
        }
      }
      stakedTokens
      queryFeeCut
      indexingRewardCut
      url
      rewardsEarned
      queryFeesCollected
      queryFeeRebates
      tokenCapacity
    }
  }
}
`;

export default allocationsQuery;
