import gql from 'graphql-tag';

export const networkQuery = gql`
query network {
  graphNetworks {
    id
    totalQueryFees
    totalSupply
    totalTokensStaked
    totalTokensSignalled
    totalDelegatedTokens
    epochLength
    networkGRTIssuance
    slashingPercentage
    maxAllocationEpochs
    subgraphCount
    thawingPeriod
    curationTaxPercentage
    curatorCount
    delegationRatio
    delegationTaxPercentage
    delegationUnbondingPeriod
    delegatorCount
    totalTokensAllocated
  }
}
`;

export const networkQueryVariables = {

};
