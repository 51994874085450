























































































import Vue from 'vue';
import { groupBy, uniqBy } from 'lodash-es';

import user from '../graphql/user';
import {
  formatBigNumber, formatGRT, formatDate, createIpfsHash, formatDecimals,
} from '../utils/helpers';

export default Vue.extend({
  name: 'Curators',

  props: {
    userId: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    user,
    headers: [
      { text: 'Subgraph name', value: 'subgraphDeployment.originalName', align: 'center' },
      { text: 'Subgraph deployment', value: 'subgraphDeployment.id', align: 'center' },
      { text: 'Allocated', value: 'allocatedTokens', align: 'center' },
      { text: 'Started', value: 'createdAt', align: 'center' },
      { text: 'Parallel allocations', value: 'parallelAllocations', align: 'center' },
      { text: 'Query fees collected', value: 'queryFeesCollected', align: 'center' },
      { text: 'Query fee rebates', value: 'queryFeeRebates', align: 'center' },
    ],
    search: '',
    sortBy: 'allocatedTokens',
    sortDesc: true,
  }),

  methods: {
    uniqBy,
    formatBigNumber,
    formatGRT,
    formatDate,
    createIpfsHash,
    formatDecimals,
    indexerNameFromAccount(account: any): string {
      return account.defaultName ? account.defaultName.name : account.id;
    },
    removeDuplicateSubgraphs(allocations: any): any {
      return uniqBy(allocations, (allocation: any) => allocation.subgraphDeployment.id);
    },
    calculateTotalParallelAllocations(allocations: any): any {
      const entries = Object.entries(
        groupBy(allocations, (allocation: any) => allocation.subgraphDeployment.id),
      )
        .map((entry: any) => {
          const [subgraphName, parallelAlocations] = entry;
          return {
            subgraphName,
            parallelAllocations: parallelAlocations.length,
          };
        });

      return entries.reduce((acc, cur) => acc + cur.parallelAllocations, 0);
    },
    calculateTotalAllocated(allocations: any): number {
      // eslint-disable-next-line
      return allocations.reduce((acc: number, { allocatedTokens }: any) => acc + (+allocatedTokens / 10 ** 18), 0);
    },
    addVirtualFields(allocations: any): any {
      const entries = Object.entries(
        groupBy(allocations, (allocation: any) => allocation.subgraphDeployment.id),
      )
        .map((entry: any) => {
          const [subgraphId, parallelAlocations] = entry;
          return {
            subgraphId,
            parallelAllocations: parallelAlocations.length,
          };
        });

      const allocationsWithVirtualFields = allocations.map((allocation: any) => {
        const matchingAllocation = entries.find(
          (entry: any) => entry.subgraphId === allocation.subgraphDeployment.id,
        );

        return {
          ...allocation,
          parallelAllocations: matchingAllocation?.parallelAllocations,
        };
      });

      return this.removeDuplicateSubgraphs(allocationsWithVirtualFields);
    },
  },
});
