var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"disclaimer px-6 py-2"},[_c('v-icon',{staticClass:"pa-2"},[_vm._v("mdi-alert")]),_vm._v(" We are currently in an early stage of the network and the functionality of the protocol is still limited. You will find more information on this subpage as soon as more functionality for curators is enabled. ")],1),_c('ApolloQuery',{attrs:{"query":_vm.curatorsQuery,"variables":_vm.curatorsQueryVariables},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
return [(!loading)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":data ? _vm.calculateCurationValue(data.curators) : [],"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"search":_vm.search,"custom-filter":_vm.looseSearch,"mobile-breakpoint":"0"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){return [_c('v-tooltip',{key:h.value,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(h.tooltip))])])]}}}),{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',{staticClass:"text-center"},[_vm._l((items),function(item,i){return _c('tr',{key:item.name},[_c('td',[_c('span',{staticClass:"rank"},[_vm._v(_vm._s(i + 1))])]),_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatBigNumber(+item.totalNameSignalledTokens))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatBigNumber(+item.totalNameUnsignalledTokens))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.curationValue)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.subgraphsCurrentlyCurating)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(+item.createdAt))+" ")])])}),(error)?[_c('tr',[_c('td',{attrs:{"colspan":"11"}},[_c('h2',[_vm._v("Live data unavailable.")])])])]:_vm._e()],2)]}}],null,true)}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }