import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: '#5847CC',
        secondary: '#0f0e24',
      },
    },
    dark: true,
  },
});
