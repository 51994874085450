var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{attrs:{"query":_vm.allocationsQuery,"variables":{
    deploymentID: _vm.subgraphId,
    first: 1000,
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var ref_result = ref.result;
  var loading = ref_result.loading;
  var error = ref_result.error;
  var data = ref_result.data;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),(data && data.allocations)?_c('h2',{staticClass:"mb-4"},[_vm._v(_vm._s(data.allocations[0].subgraphDeployment.originalName))]):_vm._e()],1),(!loading)?_c('v-data-table',{staticClass:"text-center",attrs:{"headers":_vm.headers,"items":data ? _vm.removeDuplicateIndexers(data.allocations) : [],"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"search":_vm.search,"mobile-breakpoint":"0"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4 search",staticStyle:{"width":"100%"},attrs:{"label":"Search for an indexer name"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"body",fn:function(ref){
  var items = ref.items;
return [_c('tbody',{staticClass:"text-center"},[_vm._l((items),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(" "+_vm._s(_vm.indexerNameFromAccount(item.indexer.account))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatBigNumber(+item.indexer.rewardsEarned))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatBigNumber(+item.indexer.stakedTokens))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatBigNumber(+item.indexer.queryFeesCollected))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatBigNumber(+item.indexer.queryFeeRebates))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.indexer.queryFeeCut / Math.pow( 10, 4 ))+"% ")]),_c('td',[_vm._v(" "+_vm._s(item.indexer.indexingRewardCut / Math.pow( 10, 4 ))+"% ")])])}),(error)?[_c('tr',[_c('td',{attrs:{"colspan":"11"}},[_c('h2',[_vm._v("Live data unavailable.")])])])]:_vm._e()],2)]}}],null,true)}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }