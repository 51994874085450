import gql from 'graphql-tag';

export const subgraphsQuery = gql`
query subgraphs($orderBy: Subgraph_orderBy, $orderDirection: OrderDirection, $first: Int, $skip: Int, $searchText: String) {
  subgraphs(
    orderBy: $orderBy, 
    orderDirection: $orderDirection, 
    first: $first, 
    skip: $skip, 
    where: {currentVersion_not: null, displayName_not: "", displayName_contains: $searchText}
  ) {
    id
    displayName
    image
    signalledTokens
    unsignalledTokens
    totalQueryFeesCollected
    currentVersion {
      id
      subgraphDeployment {
        id
        stakedTokens
        signalledTokens
        queryFeesAmount
        queryFeeRebates
      }
    }
  }
}
`;

export const subgraphQueryVariables = {
  orderBy: 'createdAt',
  orderDirection: 'desc',
  first: 1000,
  skip: 0,
  searchText: '',
};
