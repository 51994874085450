import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

// interface State {
//   //
// }

export interface Commit<T> {
  commit: (mutation: string, payload?: T) => void;
}

export default new Vuex.Store({
  state: {

  },
  mutations: {

  },
  actions: {

  },
  getters: {

  },
});
