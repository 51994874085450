var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"disclaimer px-6 py-2"},[_c('v-icon',{staticClass:"pa-2"},[_vm._v("mdi-alert")]),_vm._v(" We are still in an early stage of the network and there is currently only one live subgraph in the mainnet. The other Subgraphs are currently hosted by The Graph team and will migrate to the mainnet at a later stage. As soon as new subgraphs get added to the mainnet, you’ll find them on this subpage. ")],1),_c('ApolloQuery',{attrs:{"query":_vm.subgraphsQuery,"variables":_vm.subgraphQueryVariables},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
return [(!loading)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":data ? _vm.addIpfsHashToTable(data.subgraphs) : [],"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"search":_vm.search,"mobile-breakpoint":"0"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){return [_c('v-tooltip',{key:h.value,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(h.tooltip))])])]}}}),{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',{staticClass:"text-center"},[_vm._l((items),function(item){return _c('tr',{key:item.name},[_c('td',[_c('v-img',{attrs:{"src":item.image,"alt":item.name,"height":"48px","width":"48px"}})],1),_c('td',[_c('router-link',{attrs:{"to":("/subgraph/" + (item.currentVersion.subgraphDeployment.id))}},[_vm._v(" "+_vm._s(item.displayName)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm.createIpfsHash(item.currentVersion.subgraphDeployment.id))+" "),_c('br'),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.currentVersion.subgraphDeployment.id))])]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDecimals(item.currentVersion.subgraphDeployment.queryFeesAmount))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDecimals(item.currentVersion.subgraphDeployment.queryFeeRebates))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatBigNumber(+item.currentVersion.subgraphDeployment.stakedTokens))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatBigNumber(+item.currentVersion.subgraphDeployment.signalledTokens))+" ")])])}),(error)?[_c('tr',[_c('td',{attrs:{"colspan":"11"}},[_c('h2',[_vm._v("Live data unavailable.")])])])]:_vm._e()],2)]}}],null,true)}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }