var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{attrs:{"query":_vm.user,"variables":{
    id: _vm.userId,
    first: 1000,
    skip: 0
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var ref_result = ref.result;
  var loading = ref_result.loading;
  var error = ref_result.error;
  var data = ref_result.data;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),(data && data.graphAccount)?_c('h2',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.indexerNameFromAccount(data.graphAccount))+" - "+_vm._s(_vm.uniqBy(data.graphAccount.indexer.allocations, function (i) { return i.subgraphDeployment.id; }).length)+" subgraphs | "+_vm._s(_vm.calculateTotalParallelAllocations(data.graphAccount.indexer.allocations))+" allocations | "+_vm._s((data.graphAccount.indexer.stakedTokens / Math.pow( 10, 18 )).toLocaleString())+" GRT staked | "+_vm._s(_vm.calculateTotalAllocated(data.graphAccount.indexer.allocations).toLocaleString())+" GRT allocated ")]):_vm._e()],1),(!loading)?_c('v-data-table',{staticClass:"text-center",attrs:{"headers":_vm.headers,"items":data ? _vm.addVirtualFields(data.graphAccount.indexer.allocations) : [],"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"search":_vm.search,"mobile-breakpoint":"0"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4 search",attrs:{"label":"Search for a subgraph by name"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"body",fn:function(ref){
  var items = ref.items;
return [_c('tbody',{staticClass:"text-center"},_vm._l((items),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(" "+_vm._s(item.subgraphDeployment.originalName)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.createIpfsHash(item.subgraphDeployment.id))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatGRT(item.allocatedTokens))+" GRT ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.parallelAllocations)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDecimals(item.queryFeesCollected))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDecimals(item.queryFeeRebates))+" ")])])}),0)]}}],null,true)}):_vm._e(),(error)?[_c('h2',[_vm._v("An error has occured: "+_vm._s(error))])]:_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }