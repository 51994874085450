



















































































































import Vue from 'vue';
import moment from 'moment';
import { orderBy } from 'lodash-es';
import { utils } from 'ethers';

import { indexersQuery, indexersQueryVariables } from '../graphql/indexers';
import { networkQuery, networkQueryVariables } from '../graphql/network';
import {
  formatBigNumber, formatNumber, formatGRT, formatDate, formatDecimals, formatParticipantName, looseSearch,
} from '../utils/helpers';
import { DELEGATION_TABLE_HEADERS } from '../utils/constants';

import { IndexerName } from '../types';

export default Vue.extend({
  name: 'CompareReturns',

  props: {
    search: {
      type: String,
      default: '',
    },
    indexerNames: {
      type: Array as () => IndexerName[],
      default: () => [],
    },
  },

  apollo: {
    graphNetworks: {
      query: networkQuery,
      variables: networkQueryVariables,
    },
  },

  data: () => ({
    indexersQuery,
    indexersQueryVariables,
    graphNetworks: [] as any,
    headers: DELEGATION_TABLE_HEADERS,
    sortDesc: true,
    sortBy: 'historicROI',
    tooltip: false,
    hovered: false,
    copied: false,
  }),

  methods: {
    formatBigNumber,
    formatNumber,
    formatGRT,
    formatDate,
    formatDecimals,
    formatParticipantName,
    looseSearch,
    calculateDailyRewards(indexer: any): number {
      const [network] = this.graphNetworks;
      const allocatedTokens = +utils.formatUnits(indexer.allocatedTokens);
      const totalTokensStaked = +utils.formatUnits(network.totalTokensStaked);
      const totalDelegatedTokens = +utils.formatUnits(network.totalDelegatedTokens);

      return allocatedTokens / (totalTokensStaked + totalDelegatedTokens);
    },
    calculateTotalRewardsInGRT(indexer: any): number {
      const TOTAL_SUPPLY = 10000000000;
      const YEARLY_INFLATION = 0.03;
      const INDEXERS_INFLATION_CUT = 0.9;
      const dailyInflation = ((YEARLY_INFLATION * TOTAL_SUPPLY) / 365) * INDEXERS_INFLATION_CUT;
      const dailyRewards = this.calculateDailyRewards(indexer);

      return dailyRewards * dailyInflation;
    },
    calculateRewardsForIndexer(indexer: any): number {
      return this.calculateTotalRewardsInGRT(indexer) * (indexer.indexingRewardCut / 10 ** 6);
    },
    calculateRewardsForDelegators(indexer: any): number {
      const totalRewardsInGRT = this.calculateTotalRewardsInGRT(indexer);

      return totalRewardsInGRT - (totalRewardsInGRT * (indexer.indexingRewardCut / 10 ** 6));
    },
    calculateDelegationCapacity(indexer: any): number {
      const stakedTokens = parseInt(utils.formatUnits(indexer.stakedTokens), 10);
      const delegatedTokens = parseInt(utils.formatUnits(indexer.delegatedTokens), 10);

      return stakedTokens * 16 - delegatedTokens;
    },
    calculateAllocated(indexer: any): number {
      const allocatedTokens = parseInt(utils.formatUnits(indexer.allocatedTokens), 10);
      const stakedTokens = parseInt(utils.formatUnits(indexer.stakedTokens), 10);
      const delegatedTokens = parseInt(utils.formatUnits(indexer.delegatedTokens), 10);

      return (allocatedTokens / (stakedTokens + delegatedTokens)) * 100;
    },
    calculateRunningSince(indexer: any): string {
      const currentDate = moment();
      const createdAt = moment(indexer.createdAt * 1000);

      return currentDate.diff(createdAt, 'days') === 0 ? 'today' : `${currentDate.diff(createdAt, 'days')} days`;
    },
    calculateHistoricROI(indexer: any): number {
      const rewardsEarned = parseInt(utils.formatUnits(indexer.rewardsEarned), 10);
      const rewardsCut = indexer.indexingRewardCut;
      const delegatedTokens = parseInt(utils.formatUnits(indexer.delegatedTokens), 10);

      return ((rewardsEarned * (1 - rewardsCut / 10 ** 6)) / delegatedTokens) * 100;
    },
    addVirtualFields(indexers: any): any {
      const indexersSortedByRewards = orderBy(indexers, (indexer: any) => +indexer.rewardsEarned, ['desc']);

      return indexersSortedByRewards
        .map((indexer: any) => {
          const indexerName = this.indexerNames.find(({ id }) => id === indexer.id) as IndexerName;

          return {
            ...indexer,
            delegationCapacity: this.calculateDelegationCapacity(indexer),
            allocated: this.calculateAllocated(indexer),
            name: indexerName ? indexerName.name : this.formatParticipantName(indexer),
            runningSince: this.calculateRunningSince(indexer),
            historicROI: this.calculateHistoricROI(indexer),
          };
        });
    },
  },
});
