import gql from 'graphql-tag';

export const curatorsQuery = gql`
    query curators(
    $orderBy: Curator_orderBy
    $orderDirection: OrderDirection
    $first: Int
    $skip: Int
    ) {
    curators(
        orderBy: $orderBy
        orderDirection: $orderDirection
        first: $first
        skip: $skip
        where: { totalNameSignalledTokens_gt: 0 }
    ) {
        id
        createdAt
        totalSignalledTokens
        totalUnsignalledTokens
        totalNameSignalledTokens
        totalNameUnsignalledTokens
        realizedRewards
        annualizedReturn
        totalReturn
        signalingEfficiency
        account {
          id
          defaultName {
              id
              name
          }
        }
        nameSignals {
          id
          nameSignal
          subgraph {
              id
              image
              signalledTokens
          }
        }
    }
    }
`;

export const curatorsQueryVariables = {
  orderBy: 'createdAt',
  orderDirection: 'asc',
  first: 1000,
  skip: 0,
};
