



























import Vue from 'vue';

import Nav from '@/components/core/Nav.vue';
import Footer from '@/components/core/Footer.vue';

export default Vue.extend({
  name: 'App',

  components: {
    Nav,
    Footer,
  },

  data: () => ({
    //
  }),

  metaInfo() {
    return {
      title: 'Graphlets | Analyze Web 3.0 Indexing Infrastructure',
      meta: [
        { name: 'description', content: 'Analyze Web 3.0 Indexing Infrastructure' },
        { property: 'og:title', content: 'Graphlets | Analyze Web 3.0 Indexing Infrastructure' },
        { property: 'og:site_name', content: 'Graphlets' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
});
