import gql from 'graphql-tag';

export const graphAccountNamesQuery = gql`
query graphAccountNames($first: Int) {
  graphAccountNames(first: $first) {
    name
    id
    graphAccount {
      id
    }
  }
}
`;

export const graphAccountNamesQueryVariables = {
  first: 1000,
};
